/* globals jQuery */
/* globals tarteaucitron */
/* globals _wsq */

// get queryString in url
function queryString(key) {
    key = key.replace(/[*+?^$.[\]{}()|\\/]/g, '\\$&'); // escape RegEx meta chars
    var match = location.search.match(
        new RegExp('[?&]' + key + '=([^&]+)(&|$)')
    );
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

// remove accents
function removeAccent(s) {
    var r = s.toLowerCase();
    r = r.replace(new RegExp('\\s', 'g'), '');
    r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
    r = r.replace(new RegExp('æ', 'g'), 'ae');
    r = r.replace(new RegExp('ç', 'g'), 'c');
    r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
    r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
    r = r.replace(new RegExp('ñ', 'g'), 'n');
    r = r.replace(new RegExp('[òóôõö]', 'g'), 'o');
    r = r.replace(new RegExp('œ', 'g'), 'oe');
    r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
    r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
    return r;
}

// for respect RGPD and CNIL
/*function enableCookieBandeau() {
    tarteaucitron.init({
        hashtag: '#tarteaucitron',
        cookieName: 'tarteaucitron',
        orientation: 'bottom',
        showAlertSmall: true,
        cookieslist: true,
        adblocker: false,
        AcceptAllCta: true,
        highPrivacy: true,
        handleBrowserDNTRequest: false,
        removeCredit: false,
        moreInfoLink: true,
        useExternalCss: false,
        readmoreLink: 'https://www.wysistat.net',
        mandatory: false,
    });

    (tarteaucitron.job = tarteaucitron.job || []).push('wysistat');
    tarteaucitron.user.wysistat = {
        cli: 'pple',
        frm: '',
        prm: '',
        ce: '',
        page: '',
        roi: '',
        prof: '',
        cpt: '',
    };
}*/

(function ($, document, window) {
    'use strict';
    $(document).ready(function () {
        // enableCookieBandeau();

        /**
         * utils function (but use jquery)
         */

        //  complete previous info in field
        function completeFieldByRequest() {
            var city = queryString('city');
            var denomination = queryString('labelSearch');
            var siret = queryString('SIRET');
            var cityProximity = queryString('cityProximity');
            if (cityProximity == 1) {
                $('#around').attr('checked', 'checked');
            }
            $('#city_value').val(city);
            $('#denomination_value').val(denomination);
            var $options = $('#department').find('option');
            var department = location.href.match(/Department-([^/]*)/);
            if (department && department[1]) {
                var search = removeAccent(department[1]);
                var regex = new RegExp(search, 'gi');
                $options.each(function () {
                    var $option = $(this);
                    $option.prop(
                        'selected',
                        removeAccent($option.text().toLowerCase()).match(
                            regex
                        ) !== null
                    );
                });
            }
            $('#siret_value').val(siret);
        }

        /**
         * Build search url
         *
         * @param {String} href
         * @param {String} type ( values 'facet' or 'search' )
         * @returns {String}
         */
        function buildSearchUrl(href, type) {
            var inputVal = encodeURIComponent($inputField.val());

            var $inputCity = $('#city_value');
            var $inputDenomination = $('#denomination_value');
            var $inputSiret = $('#siret_value');

            if (!href.match(/recherche/)) {
                href = '/recherche//';
            }

            href = href.replace(
                /recherche\/([^/]*)(\/)?/,
                'recherche/' + inputVal + '/'
            );

            // remove pagination
            href = href.replace(/&page=[0-9]*/, '');

            var pattern = new RegExp('\\?');

            // city input
            if (href.match(/&?city=([^&]*)/)) {
                var match = href.match(/&?city=([^&]*)/)[1],
                    regexp = /[0-9]{5} - [^&]*/;

                cityList = regexp.test(decodeURIComponent(match));
            }

            if ($inputCity.val()) {
                href = href.replace(/&?city=([^&]*)/, '');
                href = !pattern.test(href) ? (href += '?') : (href += '&');
                href +=
                    'city=' +
                    encodeURIComponent($inputCity.val().toUpperCase());
            }

            // denomination input
            if ($inputDenomination.val()) {
                href = href.replace(/&?labelSearch=([^&]*)/, '');
                href = !pattern.test(href) ? (href += '?') : (href += '&');
                href +=
                    'labelSearch=' +
                    encodeURIComponent($inputDenomination.val());
            }

            if (type === 'search') {
                // DEPARTMENT SELECT
                var val = $('#department').find(':selected').val();

                if (val) {
                    if (href.match(/Department-[^/]*/)) {
                        href = href.replace(/Department-[^/?]*/, val);
                    } else {
                        href = href.replace(
                            /(recherche\/[^/]*\/)/,
                            '$1' + val + '/'
                        );
                    }
                }
            }

            // SIRET input
            if ($inputSiret.val()) {
                href = href.replace(/&?SIRET=([^&]*)/, '');
                href = !pattern.test(href) ? (href += '?') : (href += '&');
                href +=
                    'SIRET=' +
                    encodeURIComponent(
                        $inputSiret.val().replace(/[ _]/g, '').trim()
                    );
            }

            // enable checkbox
            href = href.replace(/&?removed=([^&]*)/, '');
            if ($('#removed').is(':checked')) {
                href = !pattern.test(href) ? (href += '?') : (href += '&');
                href += 'removed=1';
            }
            // city checkbox
            href = href.replace(/&?cityProximity=([^&]*)/, '');
            if ($('#around').is(':checked')) {
                href = !pattern.test(href) ? (href += '?') : (href += '&');
                href += 'cityProximity=1';
            }
            // cityList flag
            href = href.replace(/&?cityList=([^&]*)/, '');
            if (cityList) {
                href = !pattern.test(href) ? (href += '?') : (href += '&');
                href += 'cityList=1';
            }

            return href;
        }

        // autocomplete for name enterprise and cityoo
        function callAutocompleteMoreCriteria() {
            $('#city_value').autocomplete({
                source: (requete, reponse) => {
                    $.ajax({
                        url: '/city-engine-autocomplete',
                        dataType: 'json',
                        data: {
                            term: $('#city_value').val(),
                        },
                        success: (data) => {
                            var req = $.ui.autocomplete.escapeRegex(
                                requete.term
                            );
                            var matcher = new RegExp('[0-9]* - ' + req, 'i');
                            reponse(
                                $.grep(data, (item) => matcher.test(item.label))
                            );
                        },
                    });
                },
                minLength: 2,
                autoFocus: true,
                delay: 300,
                appendTo: 'div.container-city',
                select: () => {
                    cityList = 1;
                },
            });

            $('#denomination_value').autocomplete({
                source: (requete, reponse) => {
                    $.ajax({
                        url: '/denomination-engine-autocomplete',
                        dataType: 'json',
                        data: {
                            term: $('#denomination_value').val(),
                        },
                        success: (data) => {
                            var req = $.ui.autocomplete.escapeRegex(
                                requete.term
                            );
                            var matcher = new RegExp(req, 'i');
                            reponse(
                                $.grep(data, (item) => matcher.test(item.label))
                            );
                        },
                    });
                },
                minLength: 2,
                autoFocus: true,
                delay: 300,
                appendTo: 'div.container-denomination',
            });
        }

        /**
         * Variables declarations
         */
        var idApeGroup = [];
        var idRegion = [];

        $('#faceting-ape .checked').each((id, item) => {
            idApeGroup.push(
                $(item).closest('li.accordion-ape-group').attr('id')
            );
        });

        $('#faceting-department .checked').each((id, item) => {
            idRegion.push($(item).closest('li.accordion-region').attr('id'));
        });

        var $moreCriteriaWrapper = $('div.moreCriteria'),
            $moreCriteriaBlock = $('div.moreCriteriaBlock'),
            $moreCriteriaTrigger = $('#moreCriteria'),
            $inputField = $('#search-input-value'),
            $facetsLists = $(
                '#faceting-ape .faceting-list .faceting-list, #faceting-department .faceting-list'
            );

        var cityList = 0; // Flag - check if city input value is selected from auto-complete list

        // add space for siren/siret value (toDo: need improve this)
        $inputField.keyup(function () {
            var value = $(this).val();
            if (value.length === 3 && value.match(/\d{3}/)) {
                $(this).val(`${value} `);
            }
            if (value.length === 7 && value.match(/\d{3}\s{1}\d{3}/)) {
                $(this).val(`${value} `);
            }
            if (
                value.length === 11 &&
                value.match(/\d{3}\s{1}\d{3}\s{1}\d{3}/)
            ) {
                $(this).val(`${value} `);
            }
        });

        /**
         * init
         */

        // add accordion
        $('.accordion-ape-group').accordion({
            collapsible: true,
            heightStyle: 'content',
            active: false,
        });

        $('.accordion-region').accordion({
            collapsible: true,
            heightStyle: 'content',
            active: false,
        });

        $.each(idApeGroup, (index, value) => {
            $('#' + value).accordion('option', 'active', 0);
        });

        $.each(idRegion, (index, value) => {
            $('#' + value).accordion('option', 'active', 0);
        });

        $('div.moreCriteria-wrapper').addClass('js');
        $moreCriteriaWrapper.toggleClass('open-background');
        $moreCriteriaBlock.toggleClass('hide');
        $('#moreCriteria')
            .find('.more-link')
            .toggle()
            .end()
            .find('.less-link')
            .toggle();

        /**
         * display more criteria wrapper after click on more-link
         */
        $('.more-link').click(() => {
            $.ajax({
                url: '/more-criteria',
            }).done((data) => {
                $('.moreCriteria-wrapper').html(data);
                $('#siret_value').inputmask([
                    '999 999 999',
                    '999 999 999 99999',
                ]);
                callAutocompleteMoreCriteria();
                completeFieldByRequest();
            });
        });

        $('.less-link').click(() => {
            $('.moreCriteria-wrapper').empty();
        });

        /*
         * check case to desactivate "all" or "none" buttons
         */
        $facetsLists.each(function (idx, list) {
            // Check if "all"/"none" buttons have to be displayed

            var $list = $(list),
                totalItems = $list.find('.faceting-item').length,
                checkedItems = $list.find('.faceting-item a.checked').length;

            if (totalItems > 3) {
                // more than 2 items and button section
                if (!checkedItems) {
                    $list.find('a.select-none').addClass('desactivated');
                }
                if (checkedItems + 1 === totalItems) {
                    $list.find('a.select-all').addClass('desactivated');
                }
            } else {
                $list.find('li.faceting-item').first().hide();
            }
        });

        /*
         *	If detail page and keyword search
         *	then highlight this keyword in detail section
         */
        if ($inputField.val() && $('#detail').length) {
            var detailContent = $('.detail-item')[0].innerHTML,
                searchNeedles = $inputField.val().split(' ');

            $('.detail-item').addClass('highlighted-result');
            searchNeedles.forEach((needle) => {
                var regexp = new RegExp(needle, 'gi');
                detailContent = detailContent.replace(
                    regexp,
                    '<em>' + needle + '</em>'
                );
            });

            $('.detail-item')[0].innerHTML = detailContent;
        }

        /**
         * search engine manager
         */

        /**
         * Toggle display of the 'morecriteria' section of search engine
         */
        $moreCriteriaTrigger.on('click', function () {
            var $this = $(this);

            $moreCriteriaWrapper.toggleClass('open-background');
            $moreCriteriaBlock.toggleClass('hide');

            $this.find('.more-link').toggle().end().find('.less-link').toggle();
        });

        /**
         * empty value when remove-icon is clicked
         */
        $('.remove-icon').on('click', function () {
            $(this).siblings('div').find('input[type=text]').val('');
        });

        function enableAllFaceting() {
            var $facetingAllKey = $(
                'li.faceting-item span.faceting-all-key a.select-all'
            );
            var $facetingNoneKey = $(
                'li.faceting-item span.faceting-all-key a.select-none'
            );
            /**
             *	"Tous" links in Facets lists Manager
             */
            $facetingAllKey.on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();

                if ($(this).hasClass('desactivated')) {
                    return;
                }

                var url = location.origin;
                var $listLinks = $(this)
                    .closest('ul.faceting-list')
                    .find('a.link-item');
                var inputKeyword = $('#search-input-value');

                if (location.pathname !== '/') {
                    url += location.pathname;
                }

                console.log($listLinks);

                $listLinks.each((id, item) => {
                    if (!$(item).hasClass('checked')) {
                        item.href.split('/').forEach((val) => {
                            if (
                                /^[0-9]{4}[A-Z]/.test(val) ||
                                /^Department-[a-zA-Z-]/.test(val)
                            ) {
                                val = val.replace(/\?.*/, '');
                                url += '/' + val;
                            }
                        });
                    }
                });

                // a modifier a terme, supprime les doublons
                // doit y avoir un slash a la fin pour permettre l'affichage des facets correctement
                url =
                    url
                        .split('/')
                        .filter(
                            (elem, index, self) => index === self.indexOf(elem)
                        )
                        .join('/') + '/';

                if (location.search) {
                    url += location.search;
                }

                if (!url.match(/recherche/)) {
                    url = url.replace(
                        location.origin,
                        location.origin + '/recherche/'
                    );
                }

                if (inputKeyword.val().length === 0) {
                    url = url.replace('/recherche/', '/recherche//');
                }

                setTimeout(() => {
                    location.assign(url);
                }, 0);
            });

            /**
             *  "Aucun" links in Facets lists Manager
             */
            $facetingNoneKey.on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();

                if ($(this).hasClass('desactivated')) {
                    return;
                }

                var url = location.origin;
                var $listLinks = $(this)
                    .closest('ul.faceting-list')
                    .find('a.link-item');

                if (location.pathname !== '/') {
                    url += location.pathname + '/';
                }

                $listLinks.each((id, item) => {
                    if ($(item).hasClass('checked')) {
                        if (/^[0-9]{4}[A-Z]/.test(item.text)) {
                            var ape = item.text.substring(0, 5);
                            url = url.replace(
                                new RegExp(ape + '.?[-a-zA-Z0-9]*/'),
                                ''
                            );
                        } else {
                            var department = item.text
                                .replace("'", '-')
                                .replace(new RegExp(/[èéêë]/g), 'e')
                                .replace('à', 'a')
                                .replace('ô', 'o');
                            url = url.replace(
                                new RegExp('Department-' + department + '/'),
                                ''
                            );
                        }
                    }
                });

                if (location.search) {
                    url += location.search;
                }

                setTimeout(() => {
                    location.assign(url);
                }, 0);
            });
        }

        enableAllFaceting();

        /**
         * autocomplete for ape in homepage
         */
        $('#naf_value').autocomplete({
            source: (requete, reponse) => {
                $.ajax({
                    url: '/ape-engine-autocomplete',
                    dataType: 'html',
                    data: {
                        term: $('#naf_value').val().toLowerCase(),
                    },
                    success: (data) => {
                        $('div.ape-results').empty().append(data);
                        $('.accordion-ape-group').accordion({
                            collapsible: true,
                            heightStyle: 'content',
                            active: false,
                        });
                        // call after each http request
                        enableAllFaceting();
                    },
                });
            },
            minLength: 0,
            autoFocus: true,
            delay: 150,
            select: () => false,
            open: function () {
                $(this).data('uiAutocomplete').menu.element.addClass('hidden');
            },
            close: () => {
                console.log('plus de données');
            },
        });

        /**
         * Autocomplete feature
         */
        $inputField.autocomplete({
            source: (requete, reponse) => {
                $.ajax({
                    url: '/search-input-value-autocomplete',
                    dataType: 'json',
                    data: {
                        term: $inputField.val(),
                    },
                    success: (data) => {
                        var req = $.ui.autocomplete.escapeRegex(requete.term);
                        var matcher = new RegExp(req, 'i');
                        reponse(
                            $.grep(data, (item) => matcher.test(item.label))
                        );
                    },
                });
            },
            minLength: 2,
            autoFocus: false,
            delay: 300,
            appendTo: 'div.container-search',
            select: function (event, ui) {
                $(this).val(ui.item.label);
                // tag wysistat
                _wsq.push(['_reset']);
                _wsq.push(['_setNom', 'pple']);
                _wsq.push(
                    [
                        '_setCompteurExtranet',
                        'MoteurRechercheAutocompletion;' + ui.item.label,
                    ],
                    ['_wysistat']
                );
                setTimeout(() => {
                    $('#submit-btn').trigger('click');
                }, 500);
            },
            focus: (event) => {
                // prevent url display in input field
                event.preventDefault();
                return false;
            },
        });

        /**
         * set Url when launch is triggered
         */

        $('/*.faceting-item a,*/ #submit-btn').click(function (e) {
            e.preventDefault();
            var type = e.currentTarget.tagName === 'INPUT' ? 'search' : 'facet';

            /* launch search only if at least one input value is set
             * or there's at least one selected facets
             */
            if ($(this).attr('id') === 'submit-btn') {
                var flag = false,
                    $inputs = $('.elastic-box-search input[type=text]');

                $.each($inputs, (index, item) => {
                    if ($(item).val()) {
                        flag = true;
                    }
                });

                // test checkbox
                if ($('#removed').is(':checked')) {
                    flag = true;
                }

                // test faceted facet list section
                if ($('.faceting-selected-list li').length) {
                    flag = true;
                }

                // test select box
                if ($('#department').find(':selected').val()) {
                    flag = true;
                }

                if (!flag) {
                    alert(
                        'Veuillez saisir au moins un critère pour lancer une recherche.'
                    );
                    return false;
                }
            }

            var hrefOrigin = this.href ? this.href : location.href;
            var href = buildSearchUrl(hrefOrigin, type);

            setTimeout(() => {
                location.assign(href);
            }, 250);
        });

        $('#search-input .remove-icon').click((e) => {
            e.preventDefault();
            $inputField.val('').attr('data-set', '');
        });

        // launch search if press enter key
        $('#search-input-value, #city_value, #denomination_value').on(
            'keypress',
            (event) => {
                if (event.which == 13) {
                    event.stopPropagation();
                    $('#submit-btn').trigger('click');
                    return false;
                }
            }
        );

        /**
         * Search block Display manager
         */
        $(window).scroll(function () {
            /* Toggle fixed position for Search block */
            var $searchBlock = $('#search');
            var $main = $('#main');
            //if ($('.more').is(':visible')) {

            window.position = $(this).scrollTop();

            if (
                $(this).scrollTop() > 192 &&
                $('.mobile, .disable-fix').length === 0
            ) {
                $searchBlock.addClass('l-fix-search');
                $main.addClass('l-fix-search');
            } else {
                $searchBlock.removeClass('l-fix-search');
                $main.removeClass('l-fix-search');
            }
        });

        /**
         * Manage "last enterprises visited" section
         */
        //if (location.pathname.match(/^\/entreprise\//)) {
        var $container = $('ul.visited-list'),
            detailsArr,
            content = '',
            pageData =
                $('.detail-result-title').text() + '|' + location.pathname;
        // get localStorage data
        detailsArr = JSON.parse(sessionStorage.getItem('pple-visited')) || [];

        // Display list of enterprises visited
        $.each(detailsArr, (index, value) => {
            var url = value.split('|')[1],
                name = value.split('|')[0];

            if (name) {
                content +=
                    '<li class="visited-list"><a href="' +
                    url +
                    '">' +
                    name +
                    '</a></li>';
            }
        });

        if (content.length > 0) {
            $('.visited').removeClass('invisible');
        }

        $container.html(content);

        // if this page isn't already stored then add it in localstorage
        if ($.inArray(pageData, detailsArr) === -1) {
            detailsArr.push(pageData);
            sessionStorage.setItem('pple-visited', JSON.stringify(detailsArr));
        }

        /**
         * manage APEList links
         */
        $('#main').on('click', 'a.ape-list-item', (e) => {
            e.preventDefault();
            var $link = $(e.target).closest('a'),
                ws = eval($link.attr('data-ws'));

            _wsq.push(['_reset']);
            _wsq.push(['_setNom', 'pple']);
            _wsq.push(ws, ['_wysistat']);
            setTimeout(() => {
                location.assign($link.attr('href'));
            }, 300);
        });

        /**
         * Interactive map manager
         */
        var $map = $('#map');
        if ($map.length) {
            // configure imagemapster
            $('#map').mapster({
                mapKey: 'data-state',
                fillColor: '4a5e6e',
                fillOpacity: 0.5,
                render_highlight: {
                    stroke: true,
                    strokeWidth: 0.4,
                    strokeColor: '4a5e6e',
                },
                render_select: {
                    stroke: true,
                    strokeWidth: 0.4,
                    strokeColor: '4a5e6e',
                },
                areas: [
                    {
                        key: 'paris',
                        includeKeys: 'paris-min',
                        stroke: true,
                        strokeWidth: 3,
                    },
                    {
                        key: 'paris-min',
                        includeKeys: 'paris',
                        stroke: true,
                        strokeWidth: 3,
                    },
                    {
                        key: 'val-de-marne',
                        includeKeys: 'banlieue-paris',
                        stroke: true,
                        strokeWidth: 3,
                    },
                    {
                        key: 'hauts-de-seine',
                        includeKeys: 'banlieue-paris',
                        stroke: true,
                        strokeWidth: 3,
                    },
                    {
                        key: 'seine-saint-denis',
                        includeKeys: 'banlieue-paris',
                        stroke: true,
                        strokeWidth: 3,
                    },
                    {
                        key: 'banlieue-paris',
                        includeKeys:
                            'seine-saint-denis, val-de-marne, hauts-de-seine',
                        stroke: true,
                        strokeWidth: 3,
                    },
                ],
                onClick: function (e) {
                    var $this = $(this),
                        dep = $this.attr('data-full');
                    // tag Wysistat
                    _wsq.push(['_reset']);
                    _wsq.push(['_setNom', 'pple']);
                    _wsq.push(
                        [
                            '_setCompteurExtranet',
                            'RechercheGeographique;' + dep,
                        ],
                        ['_wysistat']
                    );
                    setTimeout(() => {
                        location.assign($this.attr('href'));
                    }, 300);

                    return false;
                },
                onStateChange: (e) => {
                    if (e.state === 'highlight') {
                        if (
                            $.inArray(e.key, [
                                'val-de-marne',
                                'hauts-de-seine',
                                'seine-saint-denis',
                                'paris',
                            ]) > -1
                        ) {
                            $('#zoom').show();
                        } else {
                            $('#zoom').hide();
                        }
                    } else {
                        $('#zoom').hide();
                    }

                    $('#map').mouseover(() => {
                        $('#zoom').hide();
                    });

                    $('#zoom').mouseover(function () {
                        $(this).hide();
                    });
                    return false;
                },
            });
        }

        /**
         * Mobile and tablet behaviour
         */
        $('.mobile h2.highlighted-title').click(function () {
            $(this).next('div').slideToggle();
        });
        $(
            '.tablet h2.title-remove-facets, .mobile h2.title-remove-facets'
        ).click(function () {
            $(this).next('ul.faceting-selected-list').slideToggle();
        });
        $('.tablet h2.title-add-facets, .mobile h2.title-add-facets').click(
            () => {
                $('div.faceting-wrapper').slideToggle();
            }
        );

        /**
         * Sorting selector
         */
        $('ul.list-sorting').on('click', 'li a', function (e) {
            var $this = $(this),
                $links = $('.list-sorting-item a:not(.active)');

            if ($this.hasClass('active')) {
                e.preventDefault();
                $this.closest('ul').toggleClass('open');
                $links.toggleClass('show-block');
            }
        });

        /**
         * lastUpdateSearch Link manager
         */
        $('#lastUpdatedSearch').on('click', function () {
            var $this = $(this);
            var ws = eval($this.attr('data-ws'));
            _wsq.push(['_reset']);
            _wsq.push(['_setNom', 'pple']);
            _wsq.push(ws, ['_wysistat']);
            var lastUpdate = $(this).find('span').text() || '';
            setTimeout(() => {
                location.assign(
                    '/recherche//?lastUpdate=' + lastUpdate + '&removed=1'
                );
            }, 300);
        });
    });
})(jQuery, document, window);
